.reg__wrapper {
  min-height: 100vh;
  background-image: url(../../../public/images/bg.png);
  background-position: right;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.reg__logo {
  margin: 20px auto;
  width: 100%;
  height: 120px;
}

.reg__card {
  width: 90vw;
  margin-bottom: 20px;
}

.flag-font {
  font-family: "Twemoji Country Flags", "Helvetica", "Comic Sans", serif;
}

@media screen and (min-width: 576px) {
  .reg__logo {
    height: 160px;
  }
}
@media screen and (min-width: 768px) {
  .reg__logo {
    height: 180px;
  }
  .reg__card {
    width: 80vw;
  }
}
@media screen and (min-width: 992px) {
  .reg__logo {
    height: 210px;
  }
  .reg__card {
    width: 60vw;
  }
}
@media screen and (min-width: 1200px) {
  .reg__logo {
    height: 250px;
  }
  .reg__card {
    width: 50vw;
  }
}
@media screen and (min-width: 1600px) {
  .reg__logo {
    height: 300px;
  }
  .reg__card {
    width: 40vw;
  }
}
